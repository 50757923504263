import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | bigint | string

export enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GÖRLI = 5,
  KOVAN = 42,
  BSC = 56,
  BSC_TESTNET = 97,
  MATIC = 137,
  MATIC_TESTNET = 80001,
  HARMONY = 1666600000,
  HARMONY_TESTNET = 1666700000,
  FANTOM = 250,
  FANTOM_TESTNET = 4002,
  HECO = 128,
  HECO_TESTNET = 256,
  ARBITRUM = 42161,
  ARBITRUM_TESTNET = 421611,
  AVALANCHE = 43114,
  AVALANCHE_TESTNET = 43113,
  OKEX = 66,
  OKEX_TESTNET = 65,
  PALM = 11297108109,
  PALM_TESTNET = 11297108099,
  MOONBEAM = 1287,
  MOONRIVER = 1285,
  XDAI = 100,
  CELO = 42220,
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP
}

export const INIT_CODE_HASH: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]:            '0x5ff2250d3f849930264d443f14a482794b12bd40ac16b457def9522f050665da',
  [ChainId.ROPSTEN]:            '0x9ee363bc4a7af5605ef17ba06c2f9447c61c22beacb5c4647efa122f3aefc8db',
  [ChainId.RINKEBY]:            '0x9ee363bc4a7af5605ef17ba06c2f9447c61c22beacb5c4647efa122f3aefc8db',
  [ChainId.GÖRLI]:              '0x5ff2250d3f849930264d443f14a482794b12bd40ac16b457def9522f050665da',
  [ChainId.KOVAN]:              '0x9ee363bc4a7af5605ef17ba06c2f9447c61c22beacb5c4647efa122f3aefc8db',
  [ChainId.BSC]:                '0x02a87956ec2f5e710fa13bbfe751d68112c843cdd501d3fcc9e744ade9c32428',
  [ChainId.BSC_TESTNET]:        '0x02a87956ec2f5e710fa13bbfe751d68112c843cdd501d3fcc9e744ade9c32428',  
  [ChainId.MATIC]:              '0x1b68a89c18551451d63580e66fda7aee3ccf09c7317f32b6747ff18b1173ad09',
  [ChainId.MATIC_TESTNET]:      '0x1b68a89c18551451d63580e66fda7aee3ccf09c7317f32b6747ff18b1173ad09',
  [ChainId.HARMONY]:            '0x02a87956ec2f5e710fa13bbfe751d68112c843cdd501d3fcc9e744ade9c32428',
  [ChainId.HARMONY_TESTNET]:    '0x02a87956ec2f5e710fa13bbfe751d68112c843cdd501d3fcc9e744ade9c32428',
  [ChainId.FANTOM]:             '0x02a87956ec2f5e710fa13bbfe751d68112c843cdd501d3fcc9e744ade9c32428',
  [ChainId.FANTOM_TESTNET]:     '0x02a87956ec2f5e710fa13bbfe751d68112c843cdd501d3fcc9e744ade9c32428',
  [ChainId.HECO]:               '0x02a87956ec2f5e710fa13bbfe751d68112c843cdd501d3fcc9e744ade9c32428',
  [ChainId.HECO_TESTNET]:       '0x02a87956ec2f5e710fa13bbfe751d68112c843cdd501d3fcc9e744ade9c32428',
  [ChainId.ARBITRUM]:           '0x02a87956ec2f5e710fa13bbfe751d68112c843cdd501d3fcc9e744ade9c32428',
  [ChainId.ARBITRUM_TESTNET]:   '0x02a87956ec2f5e710fa13bbfe751d68112c843cdd501d3fcc9e744ade9c32428',
  [ChainId.AVALANCHE]:          '0x02a87956ec2f5e710fa13bbfe751d68112c843cdd501d3fcc9e744ade9c32428',
  [ChainId.AVALANCHE_TESTNET]:  '0x02a87956ec2f5e710fa13bbfe751d68112c843cdd501d3fcc9e744ade9c32428',
  [ChainId.OKEX]:               '0x02a87956ec2f5e710fa13bbfe751d68112c843cdd501d3fcc9e744ade9c32428',
  [ChainId.OKEX_TESTNET]:       '0x02a87956ec2f5e710fa13bbfe751d68112c843cdd501d3fcc9e744ade9c32428',
  [ChainId.PALM]:               '0x02a87956ec2f5e710fa13bbfe751d68112c843cdd501d3fcc9e744ade9c32428',
  [ChainId.PALM_TESTNET]:       '0x02a87956ec2f5e710fa13bbfe751d68112c843cdd501d3fcc9e744ade9c32428',
  [ChainId.MOONBEAM]:           '0x02a87956ec2f5e710fa13bbfe751d68112c843cdd501d3fcc9e744ade9c32428',
  [ChainId.MOONRIVER]:          '0x02a87956ec2f5e710fa13bbfe751d68112c843cdd501d3fcc9e744ade9c32428',
  [ChainId.XDAI]:               '0x02a87956ec2f5e710fa13bbfe751d68112c843cdd501d3fcc9e744ade9c32428',
  [ChainId.CELO]:               '0x02a87956ec2f5e710fa13bbfe751d68112c843cdd501d3fcc9e744ade9c32428'
}

export const NFT_BID_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]:            '0x0aB276b92a6E6d3EcC8D5888D1b15EffEa223923',
  [ChainId.ROPSTEN]:            '0x06C2De45973Df34DaB22AD0b767d2bE3eca5D178',
  [ChainId.RINKEBY]:            '0x06C2De45973Df34DaB22AD0b767d2bE3eca5D178',
  [ChainId.GÖRLI]:              '0x06C2De45973Df34DaB22AD0b767d2bE3eca5D178',
  [ChainId.KOVAN]:              '0x06C2De45973Df34DaB22AD0b767d2bE3eca5D178',
  [ChainId.BSC]:                '0x928d6F271F4CdEa1A544F40898350d1Ba55018D4',
  [ChainId.BSC_TESTNET]:        '0x928d6F271F4CdEa1A544F40898350d1Ba55018D4',
  [ChainId.MATIC]:              '0xA2f089377f4Dddf971ba65a69Fb4DFDD5fAf16Bb',
  [ChainId.MATIC_TESTNET]:      '0xf6475c3143574F4d84c627fea3df81036ceeDAC4',
  [ChainId.HARMONY]:            '0x928d6F271F4CdEa1A544F40898350d1Ba55018D4',
  [ChainId.HARMONY_TESTNET]:    '0x928d6F271F4CdEa1A544F40898350d1Ba55018D4',
  [ChainId.FANTOM]:             '0x928d6F271F4CdEa1A544F40898350d1Ba55018D4',
  [ChainId.FANTOM_TESTNET]:     '0x928d6F271F4CdEa1A544F40898350d1Ba55018D4',
  [ChainId.HECO]:               '0x928d6F271F4CdEa1A544F40898350d1Ba55018D4',
  [ChainId.HECO_TESTNET]:       '0x928d6F271F4CdEa1A544F40898350d1Ba55018D4',
  [ChainId.ARBITRUM]:           '0x928d6F271F4CdEa1A544F40898350d1Ba55018D4',
  [ChainId.ARBITRUM_TESTNET]:   '0x928d6F271F4CdEa1A544F40898350d1Ba55018D4',
  [ChainId.AVALANCHE]:          '0x928d6F271F4CdEa1A544F40898350d1Ba55018D4',
  [ChainId.AVALANCHE_TESTNET]:  '0x928d6F271F4CdEa1A544F40898350d1Ba55018D4',
  [ChainId.OKEX]:               '0x928d6F271F4CdEa1A544F40898350d1Ba55018D4',
  [ChainId.OKEX_TESTNET]:       '0x928d6F271F4CdEa1A544F40898350d1Ba55018D4',
  [ChainId.PALM]:               '0x928d6F271F4CdEa1A544F40898350d1Ba55018D4',
  [ChainId.PALM_TESTNET]:       '0x928d6F271F4CdEa1A544F40898350d1Ba55018D4',
  [ChainId.MOONBEAM]:           '0x928d6F271F4CdEa1A544F40898350d1Ba55018D4',
  [ChainId.MOONRIVER]:          '0x928d6F271F4CdEa1A544F40898350d1Ba55018D4',
  [ChainId.XDAI]:               '0x928d6F271F4CdEa1A544F40898350d1Ba55018D4',
  [ChainId.CELO]:               '0x928d6F271F4CdEa1A544F40898350d1Ba55018D4'
}

export const SPONSOR_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]:            '0x74B6F6884FE98259aF4127ca9A5D580Da934E52b',
  [ChainId.ROPSTEN]:            '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.RINKEBY]:            '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.GÖRLI]:              '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.KOVAN]:              '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.BSC]:                '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.BSC_TESTNET]:        '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.MATIC]:              '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.MATIC_TESTNET]:      '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.HARMONY]:            '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.HARMONY_TESTNET]:    '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.FANTOM]:             '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.FANTOM_TESTNET]:     '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.HECO]:               '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.HECO_TESTNET]:       '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.ARBITRUM]:           '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.ARBITRUM_TESTNET]:   '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.AVALANCHE]:          '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.AVALANCHE_TESTNET]:  '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.OKEX]:               '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.OKEX_TESTNET]:       '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.PALM]:               '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.PALM_TESTNET]:       '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.MOONBEAM]:           '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.MOONRIVER]:          '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.XDAI]:               '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234',
  [ChainId.CELO]:               '0xB7196A981De991cdCAEe06Eb7c39c84B5277d234'
}

export const FACTORY_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]:            '0xEDc22C273ea25EeDA49F049e528150dBA367Da9A',
  [ChainId.ROPSTEN]:            '0x75f7b730c51610aba6f3d89deb4864f156c8e747',
  [ChainId.RINKEBY]:            '0x75f7b730c51610aba6f3d89deb4864f156c8e747',
  [ChainId.GÖRLI]:              '0x1BdB1555bDc425183ad56FcB31c06205726FEFB0',
  [ChainId.KOVAN]:              '0x75f7b730c51610aba6f3d89deb4864f156c8e747',
  [ChainId.BSC]:                '0x69752367a0720963Aec78760EeAa208aFcF09122',
  [ChainId.BSC_TESTNET]:        '0x69752367a0720963Aec78760EeAa208aFcF09122',
  [ChainId.MATIC]:              '0x91289e8150E20Ff7CA8478dAd6DCC55D5c85Ac2D',
  [ChainId.MATIC_TESTNET]:      '0xD21E281493C0c63f6C0B7929D83Aa5E87a83B881',
  [ChainId.HARMONY]:            '0x69752367a0720963Aec78760EeAa208aFcF09122',
  [ChainId.HARMONY_TESTNET]:    '0x69752367a0720963Aec78760EeAa208aFcF09122',
  [ChainId.FANTOM]:             '0x69752367a0720963Aec78760EeAa208aFcF09122',
  [ChainId.FANTOM_TESTNET]:     '0x69752367a0720963Aec78760EeAa208aFcF09122',
  [ChainId.HECO]:               '0x69752367a0720963Aec78760EeAa208aFcF09122',
  [ChainId.HECO_TESTNET]:       '0x69752367a0720963Aec78760EeAa208aFcF09122',
  [ChainId.ARBITRUM]:           '0x69752367a0720963Aec78760EeAa208aFcF09122',
  [ChainId.ARBITRUM_TESTNET]:   '0x69752367a0720963Aec78760EeAa208aFcF09122',
  [ChainId.AVALANCHE]:          '0x69752367a0720963Aec78760EeAa208aFcF09122',
  [ChainId.AVALANCHE_TESTNET]:  '0x69752367a0720963Aec78760EeAa208aFcF09122',
  [ChainId.OKEX]:               '0x69752367a0720963Aec78760EeAa208aFcF09122',
  [ChainId.OKEX_TESTNET]:       '0x69752367a0720963Aec78760EeAa208aFcF09122',
  [ChainId.PALM]:               '0x69752367a0720963Aec78760EeAa208aFcF09122',
  [ChainId.PALM_TESTNET]:       '0x69752367a0720963Aec78760EeAa208aFcF09122',
  [ChainId.MOONBEAM]:           '0x69752367a0720963Aec78760EeAa208aFcF09122',
  [ChainId.MOONRIVER]:          '0x69752367a0720963Aec78760EeAa208aFcF09122',
  [ChainId.XDAI]:               '0x69752367a0720963Aec78760EeAa208aFcF09122',
  [ChainId.CELO]:               '0x69752367a0720963Aec78760EeAa208aFcF09122'
}

export const ROUTER_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]:            '0xc41FaBb87b6D35CC539bF9dA6c974ed2434A6DbC',
  [ChainId.RINKEBY]:            '0x657db4e8c4258570cc7dd61031777901439e8079',
  [ChainId.ROPSTEN]:            '0x657db4e8c4258570cc7dd61031777901439e8079',
  [ChainId.GÖRLI]:              '0xD5e8666620eaf809D32c5F2D739C49953FBd6e12',
  [ChainId.KOVAN]:              '0x657db4e8c4258570cc7dd61031777901439e8079',
  [ChainId.BSC]:                '0x364cD4f1202a48e9e6C6De502B2dde572Ef57281',
  [ChainId.BSC_TESTNET]:        '0x364cD4f1202a48e9e6C6De502B2dde572Ef57281',
  [ChainId.MATIC]:              '0x938B544Ce2AE40B6dE0Ab728a69c37A60159689A',
  [ChainId.MATIC_TESTNET]:      '0x75bCDf4e9900Fac6D8E601624435d9269bAD9051',
  [ChainId.HARMONY]:            '0x364cD4f1202a48e9e6C6De502B2dde572Ef57281',
  [ChainId.HARMONY_TESTNET]:    '0x364cD4f1202a48e9e6C6De502B2dde572Ef57281',
  [ChainId.FANTOM]:             '0x364cD4f1202a48e9e6C6De502B2dde572Ef57281',
  [ChainId.FANTOM_TESTNET]:     '0x364cD4f1202a48e9e6C6De502B2dde572Ef57281',
  [ChainId.HECO]:               '0x364cD4f1202a48e9e6C6De502B2dde572Ef57281',
  [ChainId.HECO_TESTNET]:       '0x364cD4f1202a48e9e6C6De502B2dde572Ef57281',
  [ChainId.ARBITRUM]:           '0x364cD4f1202a48e9e6C6De502B2dde572Ef57281',
  [ChainId.ARBITRUM_TESTNET]:   '0x364cD4f1202a48e9e6C6De502B2dde572Ef57281',
  [ChainId.AVALANCHE]:          '0x364cD4f1202a48e9e6C6De502B2dde572Ef57281',
  [ChainId.AVALANCHE_TESTNET]:  '0x364cD4f1202a48e9e6C6De502B2dde572Ef57281',
  [ChainId.OKEX]:               '0x364cD4f1202a48e9e6C6De502B2dde572Ef57281',
  [ChainId.OKEX_TESTNET]:       '0x364cD4f1202a48e9e6C6De502B2dde572Ef57281',
  [ChainId.PALM]:               '0x364cD4f1202a48e9e6C6De502B2dde572Ef57281',
  [ChainId.PALM_TESTNET]:       '0x364cD4f1202a48e9e6C6De502B2dde572Ef57281',
  [ChainId.MOONBEAM]:           '0x364cD4f1202a48e9e6C6De502B2dde572Ef57281',
  [ChainId.MOONRIVER]:          '0x364cD4f1202a48e9e6C6De502B2dde572Ef57281',
  [ChainId.XDAI]:               '0x364cD4f1202a48e9e6C6De502B2dde572Ef57281',
  [ChainId.CELO]:               '0x364cD4f1202a48e9e6C6De502B2dde572Ef57281'
}

export const FESW_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]:            '0x4269eaec0710b874ea55e2AeDc8Fb66223522Bbe',
  [ChainId.ROPSTEN]:            '0xcfcC81C508a8025879a27257cC0f699F9f2016AB',
  [ChainId.RINKEBY]:            '0xcfcC81C508a8025879a27257cC0f699F9f2016AB',
  [ChainId.GÖRLI]:              '0xcfcC81C508a8025879a27257cC0f699F9f2016AB',
  [ChainId.KOVAN]:              '0xcfcC81C508a8025879a27257cC0f699F9f2016AB',
  [ChainId.BSC]:                '0x31C6ba28f9692564a6ebd516676f2D49ac8C0dba',
  [ChainId.BSC_TESTNET]:        '0x31C6ba28f9692564a6ebd516676f2D49ac8C0dba',
  [ChainId.MATIC]:              '0x0bE3afD0A28F0AA787D113c08d1d8A903cF6eeE9',
  [ChainId.MATIC_TESTNET]:      '0x31C6ba28f9692564a6ebd516676f2D49ac8C0dba',
  [ChainId.HARMONY]:            '0x31C6ba28f9692564a6ebd516676f2D49ac8C0dba',
  [ChainId.HARMONY_TESTNET]:    '0x31C6ba28f9692564a6ebd516676f2D49ac8C0dba',
  [ChainId.FANTOM]:             '0x31C6ba28f9692564a6ebd516676f2D49ac8C0dba',
  [ChainId.FANTOM_TESTNET]:     '0x31C6ba28f9692564a6ebd516676f2D49ac8C0dba',
  [ChainId.HECO]:               '0x31C6ba28f9692564a6ebd516676f2D49ac8C0dba',
  [ChainId.HECO_TESTNET]:       '0x31C6ba28f9692564a6ebd516676f2D49ac8C0dba',
  [ChainId.ARBITRUM]:           '0x31C6ba28f9692564a6ebd516676f2D49ac8C0dba',
  [ChainId.ARBITRUM_TESTNET]:   '0x31C6ba28f9692564a6ebd516676f2D49ac8C0dba',
  [ChainId.AVALANCHE]:          '0x31C6ba28f9692564a6ebd516676f2D49ac8C0dba',
  [ChainId.AVALANCHE_TESTNET]:  '0x31C6ba28f9692564a6ebd516676f2D49ac8C0dba',
  [ChainId.OKEX]:               '0x31C6ba28f9692564a6ebd516676f2D49ac8C0dba',
  [ChainId.OKEX_TESTNET]:       '0x31C6ba28f9692564a6ebd516676f2D49ac8C0dba',
  [ChainId.PALM]:               '0x31C6ba28f9692564a6ebd516676f2D49ac8C0dba',
  [ChainId.PALM_TESTNET]:       '0x31C6ba28f9692564a6ebd516676f2D49ac8C0dba',
  [ChainId.MOONBEAM]:           '0x31C6ba28f9692564a6ebd516676f2D49ac8C0dba',
  [ChainId.MOONRIVER]:          '0x31C6ba28f9692564a6ebd516676f2D49ac8C0dba',
  [ChainId.XDAI]:               '0x31C6ba28f9692564a6ebd516676f2D49ac8C0dba',
  [ChainId.CELO]:               '0x31C6ba28f9692564a6ebd516676f2D49ac8C0dba'
}

export const STAKE_FACTORY_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]:            '0x9f35C6E6bb19A4cd7b1527CbCEB7d70be1021675',
  [ChainId.ROPSTEN]:            '0x940EA0910C2B71794e7Bc411ea6cf45091B1d1C4',
  [ChainId.RINKEBY]:            '0x940EA0910C2B71794e7Bc411ea6cf45091B1d1C4',
  [ChainId.GÖRLI]:              '0x940EA0910C2B71794e7Bc411ea6cf45091B1d1C4',
  [ChainId.KOVAN]:              '0x940EA0910C2B71794e7Bc411ea6cf45091B1d1C4',
  [ChainId.BSC]:                '0x9B592DbCf45F8260657BC9Ac8BB3bCFE2C83d99C',
  [ChainId.BSC_TESTNET]:        '0x9B592DbCf45F8260657BC9Ac8BB3bCFE2C83d99C',
  [ChainId.MATIC]:              '0xE499Ee63F5aD4B70F7931Ab81bc8D8a8F8F2F66e',
  [ChainId.MATIC_TESTNET]:      '0x9B592DbCf45F8260657BC9Ac8BB3bCFE2C83d99C',
  [ChainId.HARMONY]:            '0x9B592DbCf45F8260657BC9Ac8BB3bCFE2C83d99C',
  [ChainId.HARMONY_TESTNET]:    '0x9B592DbCf45F8260657BC9Ac8BB3bCFE2C83d99C',
  [ChainId.FANTOM]:             '0x9B592DbCf45F8260657BC9Ac8BB3bCFE2C83d99C',
  [ChainId.FANTOM_TESTNET]:     '0x9B592DbCf45F8260657BC9Ac8BB3bCFE2C83d99C',
  [ChainId.HECO]:               '0x9B592DbCf45F8260657BC9Ac8BB3bCFE2C83d99C',
  [ChainId.HECO_TESTNET]:       '0x9B592DbCf45F8260657BC9Ac8BB3bCFE2C83d99C',
  [ChainId.ARBITRUM]:           '0x9B592DbCf45F8260657BC9Ac8BB3bCFE2C83d99C',
  [ChainId.ARBITRUM_TESTNET]:   '0x9B592DbCf45F8260657BC9Ac8BB3bCFE2C83d99C',
  [ChainId.AVALANCHE]:          '0x9B592DbCf45F8260657BC9Ac8BB3bCFE2C83d99C',
  [ChainId.AVALANCHE_TESTNET]:  '0x9B592DbCf45F8260657BC9Ac8BB3bCFE2C83d99C',
  [ChainId.OKEX]:               '0x9B592DbCf45F8260657BC9Ac8BB3bCFE2C83d99C',
  [ChainId.OKEX_TESTNET]:       '0x9B592DbCf45F8260657BC9Ac8BB3bCFE2C83d99C',
  [ChainId.PALM]:               '0x9B592DbCf45F8260657BC9Ac8BB3bCFE2C83d99C',
  [ChainId.PALM_TESTNET]:       '0x9B592DbCf45F8260657BC9Ac8BB3bCFE2C83d99C',
  [ChainId.MOONBEAM]:           '0x9B592DbCf45F8260657BC9Ac8BB3bCFE2C83d99C',
  [ChainId.MOONRIVER]:          '0x9B592DbCf45F8260657BC9Ac8BB3bCFE2C83d99C',
  [ChainId.XDAI]:               '0x9B592DbCf45F8260657BC9Ac8BB3bCFE2C83d99C',
  [ChainId.CELO]:               '0x9B592DbCf45F8260657BC9Ac8BB3bCFE2C83d99C'
}

export const GOVERNANCE_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]:            '0x77F98c147a37564c32E48054Bff7692A1F97f343',
  [ChainId.ROPSTEN]:            '0x179E988DaE54D010e86F288C4872d7d3eB4EF0C6',
  [ChainId.RINKEBY]:            '0x179E988DaE54D010e86F288C4872d7d3eB4EF0C6',
  [ChainId.GÖRLI]:              '0x179E988DaE54D010e86F288C4872d7d3eB4EF0C6',
  [ChainId.KOVAN]:              '0x179E988DaE54D010e86F288C4872d7d3eB4EF0C6',
  [ChainId.BSC]:                '0x7725f9A802E1612eC5e5Bf65A73D4318cdA0C4a9',
  [ChainId.BSC_TESTNET]:        '0x7725f9A802E1612eC5e5Bf65A73D4318cdA0C4a9',
  [ChainId.MATIC]:              '0x64BEeef954BDf345CeE176E37dd622Ce1d39c55D',
  [ChainId.MATIC_TESTNET]:      '0x7725f9A802E1612eC5e5Bf65A73D4318cdA0C4a9',
  [ChainId.HARMONY]:            '0x7725f9A802E1612eC5e5Bf65A73D4318cdA0C4a9',
  [ChainId.HARMONY_TESTNET]:    '0x7725f9A802E1612eC5e5Bf65A73D4318cdA0C4a9',
  [ChainId.FANTOM]:             '0x7725f9A802E1612eC5e5Bf65A73D4318cdA0C4a9',
  [ChainId.FANTOM_TESTNET]:     '0x7725f9A802E1612eC5e5Bf65A73D4318cdA0C4a9',
  [ChainId.HECO]:               '0x7725f9A802E1612eC5e5Bf65A73D4318cdA0C4a9',
  [ChainId.HECO_TESTNET]:       '0x7725f9A802E1612eC5e5Bf65A73D4318cdA0C4a9',
  [ChainId.ARBITRUM]:           '0x7725f9A802E1612eC5e5Bf65A73D4318cdA0C4a9',
  [ChainId.ARBITRUM_TESTNET]:   '0x7725f9A802E1612eC5e5Bf65A73D4318cdA0C4a9',
  [ChainId.AVALANCHE]:          '0x7725f9A802E1612eC5e5Bf65A73D4318cdA0C4a9',
  [ChainId.AVALANCHE_TESTNET]:  '0x7725f9A802E1612eC5e5Bf65A73D4318cdA0C4a9',
  [ChainId.OKEX]:               '0x7725f9A802E1612eC5e5Bf65A73D4318cdA0C4a9',
  [ChainId.OKEX_TESTNET]:       '0x7725f9A802E1612eC5e5Bf65A73D4318cdA0C4a9',
  [ChainId.PALM]:               '0x7725f9A802E1612eC5e5Bf65A73D4318cdA0C4a9',
  [ChainId.PALM_TESTNET]:       '0x7725f9A802E1612eC5e5Bf65A73D4318cdA0C4a9',
  [ChainId.MOONBEAM]:           '0x7725f9A802E1612eC5e5Bf65A73D4318cdA0C4a9',
  [ChainId.MOONRIVER]:          '0x7725f9A802E1612eC5e5Bf65A73D4318cdA0C4a9',
  [ChainId.XDAI]:               '0x7725f9A802E1612eC5e5Bf65A73D4318cdA0C4a9',
  [ChainId.CELO]:               '0x7725f9A802E1612eC5e5Bf65A73D4318cdA0C4a9'
}

export const TIMELOCK_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]:            '0xd24347C40f4ed36f326f82E3bEFFfaf3B8D436a1',
  [ChainId.ROPSTEN]:            '0xFA2Dbaa137b3Bd13d8f1758311Ae909397EC18AB',
  [ChainId.RINKEBY]:            '0xFA2Dbaa137b3Bd13d8f1758311Ae909397EC18AB',
  [ChainId.GÖRLI]:              '0xFA2Dbaa137b3Bd13d8f1758311Ae909397EC18AB',
  [ChainId.KOVAN]:              '0xFA2Dbaa137b3Bd13d8f1758311Ae909397EC18AB',
  [ChainId.BSC]:                '0xC2078563d1cE2B8fF241A087B37344A7Ea0a07Dd',
  [ChainId.BSC_TESTNET]:        '0xC2078563d1cE2B8fF241A087B37344A7Ea0a07Dd',
  [ChainId.MATIC]:              '0x858ebb27eE7EeEA06426171e58CA14D4fA3b77A9',
  [ChainId.MATIC_TESTNET]:      '0xC2078563d1cE2B8fF241A087B37344A7Ea0a07Dd',
  [ChainId.HARMONY]:            '0xC2078563d1cE2B8fF241A087B37344A7Ea0a07Dd',
  [ChainId.HARMONY_TESTNET]:    '0xC2078563d1cE2B8fF241A087B37344A7Ea0a07Dd',
  [ChainId.FANTOM]:             '0xC2078563d1cE2B8fF241A087B37344A7Ea0a07Dd',
  [ChainId.FANTOM_TESTNET]:     '0xC2078563d1cE2B8fF241A087B37344A7Ea0a07Dd',
  [ChainId.HECO]:               '0xC2078563d1cE2B8fF241A087B37344A7Ea0a07Dd',
  [ChainId.HECO_TESTNET]:       '0xC2078563d1cE2B8fF241A087B37344A7Ea0a07Dd',
  [ChainId.ARBITRUM]:           '0xC2078563d1cE2B8fF241A087B37344A7Ea0a07Dd',
  [ChainId.ARBITRUM_TESTNET]:   '0xC2078563d1cE2B8fF241A087B37344A7Ea0a07Dd',
  [ChainId.AVALANCHE]:          '0xC2078563d1cE2B8fF241A087B37344A7Ea0a07Dd',
  [ChainId.AVALANCHE_TESTNET]:  '0xC2078563d1cE2B8fF241A087B37344A7Ea0a07Dd',
  [ChainId.OKEX]:               '0xC2078563d1cE2B8fF241A087B37344A7Ea0a07Dd',
  [ChainId.OKEX_TESTNET]:       '0xC2078563d1cE2B8fF241A087B37344A7Ea0a07Dd',
  [ChainId.PALM]:               '0xC2078563d1cE2B8fF241A087B37344A7Ea0a07Dd',
  [ChainId.PALM_TESTNET]:       '0xC2078563d1cE2B8fF241A087B37344A7Ea0a07Dd',
  [ChainId.MOONBEAM]:           '0xC2078563d1cE2B8fF241A087B37344A7Ea0a07Dd',
  [ChainId.MOONRIVER]:          '0xC2078563d1cE2B8fF241A087B37344A7Ea0a07Dd',
  [ChainId.XDAI]:               '0xC2078563d1cE2B8fF241A087B37344A7Ea0a07Dd',
  [ChainId.CELO]:               '0xC2078563d1cE2B8fF241A087B37344A7Ea0a07Dd'
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
}
